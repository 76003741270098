<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.search_area')" :placeholder="$t('general.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
                <b-col md="4" v-if="userType=='admin' || userType=='clinic'">
                    <base-input :label="$t('availability.doctor_list')">
                        <el-select v-model="filter.doctor" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option value="">{{$t('general.select')}}</el-option>
                            <el-option v-for="(item, index) in doctors" :key="index" :label="item.title+item.name+' - '+item.email+' - '+item.phone" :value="item.uuid"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="3">
                    <v-date-picker v-model="range" :locale="userLang" mode="dates" :masks="masks" is-range @input="setDates">
                        <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <b-row>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.start_date')}}</label>
                                    <input class="form-control"  :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.start" v-on="inputEvents.start" />
                                </b-col>
                                <b-col md="6">
                                    <label class="form-control-label">{{$t('availability.end_date')}}</label>
                                    <input class="form-control"  :class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value="inputValue.end" v-on="inputEvents.end" />
                                </b-col>
                            </b-row>
                        </template>
                    </v-date-picker>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('availability.type')">
                        <el-select v-model="filter.type" filterable="" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option :label="$t('availability.online')" value="online"></el-option>
                            <el-option :label="$t('availability.normal')" value="normal"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.currency')">
                        <el-select v-model="filter.currency" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in currencyList" :key="key" :label="item.toUpperCase()" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <base-input :label="$t('payment.appointment_status')">
                    <el-select v-model="filter.appointmentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                        <el-option v-for="(item, key) in ['completed','waiting','canceled']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                    </el-select>
                </base-input>
                <b-col md="2">
                    <base-input :label="$t('general.payment_status')">
                        <el-select v-model="filter.paymentStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting','canceled']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2">
                    <base-input :label="$t('general.payment_method')">
                        <el-select v-model="filter.paymentMethod" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['creditcard','banktransfer']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="2" v-if="userType!='clinic'">
                    <base-input :label="$t('general.earn_status')">
                        <el-select v-model="filter.earnStatus" clearable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, key) in ['completed','waiting']" :key="key" :label="$t('general.'+item)" :value="item"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body v-if="payments" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="payments" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.appointment_id')" min-width="280">
                    <template v-slot="{row}">
                        <small>{{row.appointment[0]!==undefined ? row.appointment[0].uuid : $t('warning.appointment_not_exists')}}</small>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_id')" prop="doctor" min-width="260" v-if="userType=='admin'">
                    <template v-slot="{row}">
                        <div v-if="row.paymentMethod=='creditcard'&&row.transaction[0]!=undefined">
                            <small>{{row.transaction[0].merchant_oid}}</small>
                        </div>
                        <div v-else>
                            <small>{{row.paymentId}}</small>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.appointment_status')" min-width="200">
                    <template v-slot="{row}">
                        <b-badge v-if="row.appointment[0]!==undefined" :variant="row.appointment[0].appointment_status=='completed'?'success':(row.appointment[0].appointment_status=='waiting'?'primary':'danger')">
                            <template v-if="row.appointment[0].appointment_status=='completed'">{{$t('general.completed')}}</template>
                            <template v-if="row.appointment[0].appointment_status=='waiting'">{{$t('general.waiting')}}</template>
                            <template v-if="row.appointment[0].appointment_status=='canceled'">{{$t('general.canceled')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_status')" min-width="180">
                    <template v-slot="{row}">
                        <b-badge :variant="row.paymentStatus=='completed'?'success':(row.paymentStatus=='waiting'?'primary':(row.paymentStatus=='refunded'?'warning':'danger'))">
                            <template v-if="row.paymentStatus=='completed'">{{$t('general.completed')}}</template>
                            <template v-if="row.paymentStatus=='waiting'">{{$t('general.waiting')}}</template>
                            <template v-if="row.paymentStatus=='canceled'">{{$t('general.canceled')}}</template>
                            <template v-if="row.paymentStatus=='refunded'">{{$t('general.refunded')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.doctor')" prop="doctor" min-width="240" v-if="userType!='doctor'">
                    <template v-slot="{row}">
                        {{row.doctor[0]!==undefined ? row.doctor[0].title+' '+row.doctor[0].name : $t('warning.doctor_not_exists')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('profile.identity')" prop="identity" min-width="160">
                    <template v-slot="{row}">
                        <span v-if="!row.patient[0].nontc">{{row.patient[0].identity}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" prop="patient" min-width="200">
                    <template v-slot="{row}">
                        {{row.patient[0]!==undefined ? row.patient[0].name : $t('warning.patient_not_exists')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.appointment')" prop="doctor" min-width="280">
                    <template v-slot="{row}">
                        <div v-if="row.availability[0]">
                            <b-badge variant="primary" class="mr-1">{{$t('availability.'+row.availability[0].type)}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability[0].date | getIntDate}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability[0].time | getIntTime}}</b-badge>
                            <b-badge variant="primary" class="mr-1">{{row.availability[0].min}} {{$t('general.min')}}</b-badge>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.payment_method')" prop="paymentMethod" min-width="160">
                    <template v-slot="{row}">
                        <b-badge :variant="row.paymentMethod=='creditcard'?'success':'primary'">
                            {{$t('general.'+row.paymentMethod)}}
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.price')" prop="price" min-width="160">
                    <template v-slot="{row}">
                        {{row.amount}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.commission')" prop="comission" min-width="160" v-if="userType!='clinic'">
                    <template v-slot="{row}">
                        {{row.commission}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.earn')" prop="earn" min-width="160" v-if="userType!='clinic'">
                    <template v-slot="{row}">
                        {{row.amount - row.commission}} {{row.currency | getCurrency}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('appointment.earn_status')" prop="earn" min-width="180" v-if="userType!='clinic'">
                    <template v-slot="{row}">
                        <b-badge :variant="row.earn?'success':'primary'">
                            <template v-if="row.earn">{{$t('general.completed')}}</template>
                            <template v-else>{{$t('general.waiting')}}</template>
                        </b-badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('payment.date')" prop="updatedAt" min-width="200">
                    <template v-slot="{row}">
                        {{row.updatedAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="170" v-if="userType=='admin'">
                    <template slot-scope="scope">
                        <base-button type="success" size="sm" @click="approvedEarn(scope.row.uuid)" v-if="scope.row.appointment[0] && scope.row.appointment[0].appointment_status=='completed'&&scope.row.paymentStatus=='completed'&&!scope.row.earn">
                            <small>{{$t('general.approved_earn')}}</small>
                        </base-button>

                        <base-button type="primary" size="sm" @click="openProcessModal(scope.$index)" v-if="scope.row.appointment[0] && scope.row.appointment[0].appointment_status=='canceled'&&scope.row.paymentStatus=='completed'">{{$t('general.process')}}</base-button>
                    
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
            <b-row class="mt-4" v-if="footer.currency !==undefined && footer.currency.length>0">
                <b-col cols="12">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead class="thead-light">
                                <tr>
                                    <th>{{$t('general.currency')}}</th>
                                    <th>{{$t('general.total_payment')}}</th>
                                    <th v-if="userType!='clinic'">{{$t('general.total_commission')}}</th>
                                    <th v-if="userType!='clinic'">{{$t('general.total_earn')}}</th>
                                    <th v-if="userType!='clinic'">{{$t('general.payed_earn')}}</th>
                                    <th>{{$t('general.pending_refund')}}</th>
                                    <th>{{$t('general.profit')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in footer.currency" :key="item">
                                    <td>{{item.toUpperCase()}}</td>
                                    <td class="text-right">{{footer.amount[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right" v-if="userType!='clinic'">{{footer.commission[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right" v-if="userType!='clinic'">{{footer.earn[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right" v-if="userType!='clinic'">{{footer.payed[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right">{{footer.refund[item]}} {{item | getCurrency}}</td>
                                    <td class="text-right">
                                        <template v-if="userType=='doctor' || userType=='clinic'">
                                            {{(footer.amount[item]-footer.commission[item])-footer.refund[item]}} {{item | getCurrency}}
                                        </template>
                                        <template v-if="userType=='admin'">
                                            {{(footer.amount[item]-footer.earn[item])-footer.refund[item]}} {{item | getCurrency}}
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <modal :show.sync="processModal.status" modal-classes="modal-danger" modal-content-classes="bg-gradient-primary" size="sm">
            <h6 slot="header" class="modal-title">{{$t('general.process')}}</h6>

            <div class="py-3 text-center">
                <base-button type="secondary" block size="sm" @click="doProcess('approved')">{{$t('general.approved_refund')}}</base-button>
                <base-button type="secondary" block size="sm" @click="doProcess('transfer')">{{$t('general.balance_transfer')}}</base-button>
            </div>

            <template slot="footer">
                <base-button type="link" class="text-white ml-auto" @click="processModal.status = false">{{$t('general.close')}}</base-button>
            </template>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { PAYMENT_REQUEST, PAYMENT_UPDATE, PAYMENT_TRANSFER } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapState(['userLang']),
        ...mapGetters({ payments: 'getPayments', total: 'getPaymentTotal', pages: 'getPaymentPages', footer: 'getPaymentFooter', userType: 'getUserType', doctors: 'getDoctors', })
    },
    data() {
        return {
            loading: false,
            masks: {
                input: 'DD.MM.YYYY'
            },
            currencyList: ['try', 'usd', 'eur', 'gbp'],
            processModal: {
                status: false,
                index: false
            },
            range: {
                start: moment()
                    .clone()
                    .startOf('month')
                    .toDate(),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .toDate()
            },
            filter: {
                text: '',
                earnStatus: '',
                appointmentStatus: '',
                paymentStatus: '',
                paymentMethod: '',
                currency: 'try',
                doctor: '',
                type: '',
                start: moment()
                    .clone()
                    .startOf('month')
                    .format('YYYYMMDD'),
                end: moment()
                    .clone()
                    .startOf('month')
                    .add(1, 'month')
                    .subtract(1, 'day')
                    .format('YYYYMMDD')
            },
            pagination: {
                limit: 10,
                page: 1
            }
        };
    },
    methods: {
        doProcess(process) {
            self.processModal.status = false;
            let row = self.payments[self.processModal.index];
            switch (process) {
                case 'approved':
                    self.approvedRefund(row.uuid);
                    break;
                case 'transfer':
                    self.transferBalance(row);
                    break;
                default:
                    break;
            }
        },
        openProcessModal(index) {
            self.processModal.index = index;
            self.processModal.status = true;
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        },
        changePage(val) {
            self.pagination.page = val;
            self.getData();
        },
        setDates() {
            _.set(self.filter, 'start', moment(self.range.start).format('YYYYMMDD'));
            _.set(self.filter, 'end', moment(self.range.end).format('YYYYMMDD'));

            self.getData();
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(PAYMENT_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        approvedEarn(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.earn_approve_confirm'),
                icon: 'success',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { earn: 'completed' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        approvedRefund(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.payment_refund_confirm'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_UPDATE, { uuid, payment: { payment_status: 'refunded' } })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        transferBalance(row) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.payment_transfer_confirm'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#2dce89',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(PAYMENT_TRANSFER, { amount: row.amount, currency:row.currency, desc: '', type: 'receivable', paymentMethod: 'transfer', payment: row.uuid, patient: row.patient[0].uuid })
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        }
    },
    created() {
        self = this;
        self.getData();

        self.$store
            .dispatch('DOCTOR_REQUEST', { pagination: { limit: 10000 }, filter: self.filter, fields: '-_id title uuid name email phone' })
            .then(resp => {
                if (self.doctors.length > 0) {
                    self.getData();
                } else {
                    self.loading = false;
                }
            })
            .catch(err => {
                self.loading = false;
                handleError(self, err);
            });
    }
};
</script>
